
import { Component, Vue } from 'vue-property-decorator';

export default class App extends Vue {
  public transition = 'slide-left';

  public created () {

    this.$router.afterEach((to, from) => {
      console.log('to', to);
      console.log('from', from);

      if ( to.name === 'default' ) {
        this.transition = 'slide-right';
      }
      else if ( from.name === 'recuperar' ) {
        this.transition = 'slide-right';
      }
      else {
        this.transition = 'slide-left';
      }
    });

  }

  public goBack () {
    return history.back();
  }
}
