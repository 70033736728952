
import Vue from 'vue';
import Component from 'vue-class-component';
import * as qs from 'query-string';

interface ErrorsMap {
  [error: string]: boolean
}

@Component
export default class LoginView extends Vue {
  public enviando = false;
  public customError = '';

  public dados = {
    email: '',
    senha: '',
  };

  public errors: ErrorsMap = {
    email: false,
    senha: false,
    custom: false,
  };

  public logar() {
    this.enviando = true;

    if (this.validar()) {
      fetch('/api/v1/logar.php', {
        method: 'POST',
        body: qs.stringify(this.dados),
      }).then(data => {
        this.enviando = false;
        return data.json();
      })
          .then((data) => {
            if (data.success) {
              window.location.href = '/painel';
            } else {
              this.errors.custom = true;
              this.customError = data.message;
            }
          });
    } else {
      this.enviando = false;
    }
  }

  get valid() {
    return Object.keys(this.errors).some((value) => this.errors[value]);
  }

  public validar() {

    let valid = true;
    if (this.dados.email.length === 0) {
      valid = false;
      this.errors.email = true;
    }
    if (this.dados.senha.length === 0) {
      valid = false;
      this.errors.senha = true;
    }
    return valid;
  }

  public esqueci() {

  }
}
